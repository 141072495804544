import React, { Component } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { Link } from 'react-router-dom';

import generatePath from '../../../util/generatePath';

const PaginationLinkLink = (props) => {
  return <PaginationLink tag={Link} {...props} />;
};

class Paginator extends Component {
  getPagePath = (page) => {
    const { pathTemplate } = this.props;
    return generatePath(pathTemplate, { page });
  };

  getPaginationItem = (page) => {
    const { currentPage, onClick } = this.props;
    return (
      <PaginationItem active={currentPage === page} key={page}>
        <PaginationLinkLink to={this.getPagePath(page)} onClick={onClick}>
          {page}
        </PaginationLinkLink>
      </PaginationItem>
    );
  };

  getMoreItem = () => {
    return (
      <PaginationItem disabled>
        <PaginationLink>...</PaginationLink>
      </PaginationItem>
    );
  };

  render() {
    const { currentPage, lastPage, pagesToShow, label, onClick } = this.props;
    const itemsToShow = Math.min(lastPage, pagesToShow);

    const maxItemsOnRight = lastPage - currentPage;
    const pagesToLeft = Math.max(
      Math.floor(itemsToShow / 2),
      itemsToShow - maxItemsOnRight - 1
    );
    const leftmostPage = Math.max(currentPage - pagesToLeft, 1);
    const leftmostItem =
      leftmostPage === 1 ? this.getPaginationItem(1) : this.getMoreItem();

    let middleItems = [];
    const firstMiddlePage = leftmostPage + 1;
    const numberOfMiddlePages = itemsToShow - 2;
    if (numberOfMiddlePages >= 1) {
      middleItems = range(
        firstMiddlePage,
        firstMiddlePage + numberOfMiddlePages
      ).map((page) => this.getPaginationItem(page));
    }

    let rightmostItem;
    const rightmostPage = leftmostPage + (itemsToShow - 1);
    if (rightmostPage > leftmostPage) {
      rightmostItem =
        rightmostPage >= lastPage
          ? this.getPaginationItem(lastPage)
          : this.getMoreItem();
    }

    const previousPage =
      currentPage > lastPage
        ? lastPage
        : currentPage <= 1
        ? currentPage
        : currentPage - 1;
    const nextPage = currentPage >= lastPage ? lastPage : currentPage + 1;

    return (
      <Pagination
        aria-label={label || 'Pagination'}
        className="Paginator"
        size="lg"
      >
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLinkLink
            first
            aria-label={'Previous'}
            to={this.getPagePath(previousPage)}
            onClick={onClick}
          />
        </PaginationItem>
        {leftmostItem}
        {middleItems}
        {rightmostItem}
        <PaginationItem disabled={currentPage >= lastPage}>
          <PaginationLinkLink
            last
            aria-label={'Next'}
            to={this.getPagePath(nextPage)}
            onClick={onClick}
          />
        </PaginationItem>
      </Pagination>
    );
  }
}

Paginator.propTypes = {
  pathTemplate: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  pagesToShow: PropTypes.number.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

Paginator.defaultProps = {
  pagesToShow: 5,
};

export default Paginator;
